import React from "react"
import { Link } from "gatsby"

const footer = props => {
  // useEffect(() => {
  //   // Initial state
  //   var scrollPos = 0
  //   // adding scroll event
  //   window.addEventListener("scroll", function() {
  //     // detects new state and compares it with the new one
  //     if (document.body.getBoundingClientRect().top > scrollPos)
  //       document
  //         .getElementById("info-box")
  //         .setAttribute("data-scroll-direction", "UP")
  //     else
  //       document
  //         .getElementById("info-box")
  //         .setAttribute("data-scroll-direction", "DOWN")
  //     // saves the new position for iteration.
  //     scrollPos = document.body.getBoundingClientRect().top
  //   })
  // })
  return (
    <div>
      <div className="footer-dark">
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-3 item">
                <h4 className="mt-0">QUICK LINKS</h4>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/inspection">Sewer Inspection Equipment</Link>
                  </li>
                  <li>
                    <Link to="/management-software">Inspection Software</Link>
                  </li>
                  <li>Sewer Cleaning Equipment</li>

                  <li>
                    <Link to="/hydro-excavation">Hydroex Equipment</Link>
                  </li>
                  <li>Services</li>
                  <li>
                    <Link to="/accessories">Parts And Accesories</Link>
                  </li>
                  <li>
                    <Link to="/rentals">Rentals</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 item">
                <h4 className="mt-0">COMPANY INFO</h4>
                <ul>
                  <li>
                    <a href="tel:3036328236">
                      <i className="fa fa-phone mr-1"></i>
                      <strong>303.632.8236</strong>
                    </a>
                  </li>
                  <li>
                    <a href="tel:8012079728">
                      {" "}
                      <i className="fa fa-phone mr-1"></i>
                      <strong>801.207.9728</strong>
                    </a>
                  </li>
                  <li>
                    <p>
                      <i className="fas fa-map-marker-alt mr-1"></i>434 E 56th
                      Ave, Denver, CO 80216
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fas fa-map-marker-alt mr-1"></i>47 South
                      Orange Street&nbsp;Suite A5-6, Salt Lake City, UT 84116
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 item text">
                <h4 className="mt-0">DAWSON INFRASTRUCTURE SOLUTIONS</h4>
                <p>
                  For environmental and inspection equipment in Denver and Utah,
                  you can rely on Dawson Infrastructure Solutions to provide you
                  with the best sales, service, parts, and rental resources. We
                  also cater to your needs in any of these areas: leak
                  monitoring, sewer cleaning, pipeline video assessment,
                  rehabilitation, hydro-excavation, and other specialized
                  utility needs.
                </p>
              </div>
            </div>
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-12 text-center">
                  <p>
                    Dawson Infrastructure Solutions © 2020. Designed And Powered
                    By&nbsp;<a href="https://www.webriq.com/">WebriQ</a>
                  </p>
                </div>
                {/*<div className="col-md-6">
                  <div className="col item social float-right">
                    <Link to="#">
                      <i className="fa fa-facebook-official" aria-hidden="true"></i>
                    </Link>
                    <Link to="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </Link>
                    <Link to="#">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default footer
