import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import Logo from "../../images/logo.png"

const Header = props => {
  console.log("propsnako", props)

  return (
    <header>
      <Sticky
        className={styl.stickyWrapper}
        stickyClassName={styl.isSticky}
        stickyStyle={{
          transform: "unset",
          zIndex: "999",
          position: "relative",
        }}
      >
        <div className={`header menu-container shadow-sm ${styl.header}`}>
          <div className="container-fluid py-2 primary-bg">
            <div className="container sub-heading">
              <div className="row">
                <div className="col-md-4">
                  <p>
                    <i className="fa fa-map-marker mr-1" aria-hidden="true"></i>
                    434 E 56th Ave, Denver, CO 80216
                  </p>
                </div>
                <div className="col">
                  <p>
                    <i className="fa fa-map-marker mr-1" aria-hidden="true"></i>
                    47 South Orange Street&nbsp;Suite A5-6, Salt Lake City, UT
                    84116
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-4">
                <div className="nav-brand">
                  <Link to="/">
                    <img className="img-fluid" src={Logo} alt="Logo" />
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="text-right">
                  <SideBarMenu category={props.category} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}

export default Header
