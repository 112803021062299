import React from "react"
import { Link } from "gatsby"
// import { Nav, Dropdown, NavItem } from "react-bootstrap"
import Collapsible from "react-collapsible"
import "./menu.css"
import Logo from "../../images/logo.png"

export default props => {
  console.log("props.category", props)
  return (
    <div className="navigation text-left">
      <div className="nav-brand-side">
        <Link to="/">
          <img className="img-fluid mb-4" src={Logo} alt="Logo" />
        </Link>
      </div>
      <ul className="list-unstyled sidenav-ul d-grid">
        <li>
          <Link to="/">Home</Link>
        </li>

        {/* <li>
        <Link to="/our-company">Our Company</Link>
      </li>*/}

        {props.category.map(ctgy => (
          <div style={{ position: "relative" }} key={ctgy.id}>
            {ctgy.slug && ctgy.slug.current ? (
              <Link to={`/${ctgy.slug.current}`}>{ctgy.title}</Link>
            ) : (
              <li>{ctgy.title}</li>
            )}

            <div className="top-inner">
              {ctgy.pages.length !== 0 ? (
                <Collapsible trigger={<i className="fa fa-chevron-down"></i>}>
                  {ctgy.pages.map(page => (
                    <li style={{ marginLeft: 18 }} key={page.id}>
                      {page.slug && page.slug.current ? (
                        <Link to={`/${page.slug.current}`} key={page.id}>
                          {page.title}
                        </Link>
                      ) : (
                        page.title
                      )}
                    </li>
                  ))}
                </Collapsible>
              ) : null}
            </div>
          </div>
        ))}

        <li>
          <Link to="/rentals">Rentals</Link>
        </li>

        <li>
          <Link to="/contact-us">Contact</Link>
        </li>
      </ul>
    </div>
  )
}
